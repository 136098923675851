import React from 'react';
import { graphql } from 'gatsby';
import { path } from 'ramda';
import moment from 'moment-timezone';
import { compose, withState, withProps } from 'recompose';
import { createSelector, createStructuredSelector } from 'reselect';

import Layout from '/src/components/layout';
import SEO from '/src/components/atoms/Seo';
import MethodPromo from '/src/components/organisms/MethodPromo';

const countdownTo = '2019-12-04T21:00:00';

const isPastCountdown = moment
  .tz(countdownTo, 'America/New_York')
  .isBefore(+new Date());

const IndexPage = ({ product }) => (
  <>
    <SEO />
    <Layout>
      <MethodPromo product={product} />
    </Layout>
  </>
);

export const query = graphql`
  query {
    stripeProduct(
      name: { eq: "The Method CD" }
      active: { eq: true }
      skus: { data: { elemMatch: { active: { eq: true } } } }
    ) {
      name
      description
      id
      skus {
        data {
          id
          attributes {
            name
          }
          price
          image
        }
      }
      metadata {
        fb_event
      }
    }
  }
`;

const productSelector = path(['data', 'stripeProduct']);

const skusSelector = createSelector(
  productSelector,
  path(['skus', 'data'])
);

const finalProductSelector = createSelector(
  productSelector,
  skusSelector,
  (product, skus) => ({
    ...product,
    skus,
  })
);

const propSelectors = createStructuredSelector({
  product: finalProductSelector,
});

export default compose(
  withState('isCountingDown', 'setIsCountingDown', !isPastCountdown),
  withProps(propSelectors)
)(IndexPage);
