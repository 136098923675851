import React from 'react';
import { Link } from 'gatsby';
import { compose as Rcompose, head, not, path, prop } from 'ramda';
import { connect } from 'react-redux';
import { compose, withProps, withHandlers } from 'recompose';
import { createSelector, createStructuredSelector } from 'reselect';

import { selectors, actions } from '/src/store/cart';
import { Row, Col } from '/src/components/atoms/GridRow';
import Button from '/src/components/atoms/Button';
import ContentSection from '/src/components/atoms/ContentSection';

import AlbumDisplay from './AlbumDisplay';
import './index.scss';

const MethodPromo = ({ onTicketClick }) => (
  <>
    <ContentSection center splash bgSrc="/images/uploads/eoa_countdown-bg.jpg">
      <Row center>
        <Col width={12}>
          <h2 className="heading1">The Method</h2>
          <p className="space-bottom">Available Now</p>
        </Col>
        <Col width={4}>
          <Button cta component={Link} to="/shop/cart" onClick={onTicketClick}>
            Order the CD
          </Button>
        </Col>
        <Col width={4}>
          <Button
            cta
            component="a"
            href="https://open.spotify.com/album/3QgAnGXkLTv8GBF30bzcgY?si=kLMCwXGQRFetTVIIn-Vpww"
            rel="nofollow"
            target="_blank"
          >
            Listen on Spotify
          </Button>
        </Col>
      </Row>
    </ContentSection>
    {/* <ContentSection center>
      <h1 className="heading2 MethodPromo__title">
        <span className="MethodPromo__date">
          <span className="MethodPromo__day">04</span>
          <span className="MethodPromo__month">Jan</span>
        </span>
        Scranton, PA
      </h1>
      <Row center>
        <Col width={8} className="tl MethodPromo__body">
          <h2 className="heading3 MethodPromo__body__title space-bottom">
            EYE ON ATTRACTION ANNOUNCES NEWEST RELEASE, ‘THE METHOD.’
          </h2>

          <p className="space-bottom">
            The first half of a two-part release, The Method features brand new
            material alongside the previously released singles Pleasantville,
            Kentucky, and The Cruelest Love.
          </p>

          <p className="space-bottom">
            The album will be released on January 1st, 2020, ten years to the
            day since the band’s formation.
          </p>

          <p className="space-bottom">
            To celebrate the release, Eye on Attraction will perform the album
            in its entirety at an intimate one night only event on Saturday,
            January 4th, 2020 at Stage West in Scranton, PA. Commemorative
            presale tickets available now.
          </p>
          <ul>
            <li>Tickets limited to 250</li>
            <li>Support TBA</li>
          </ul>
        </Col>
      </Row>
    </ContentSection> */}
    <ContentSection center>
      <h2 className="sr-only">Album Preview</h2>
      <Row center>
        <Col width={8}>
          <div className="space-top space-bottom">
            <AlbumDisplay
              title="The Method"
              coverSrc="/images/uploads/method_cover.jpg"
              tracks={[
                'Hush',
                'Pleasantville',
                'The Cruelest Love',
                'Kentucky',
                'The Final Problem',
                'Strangers In A Broken Land, Part 1: Breach',
                'Consumer',
              ]}
            />
          </div>
          <div className="space-top space-bottom">
            <AlbumDisplay
              title="The Madness"
              coverSrc="/images/uploads/madness_blur.jpg"
            />
          </div>
        </Col>
      </Row>
    </ContentSection>
    {/* <ContentSection center>
      <h2 className="heading2">Subscribe to our Newsletter!</h2>
      <Row center>
        <Col width={8}></Col>
      </Row>
    </ContentSection> */}
  </>
);

const mapDispatchToProps = {
  addItem: actions.addItem,
};

const skusSelector = createSelector(
  prop('product'),
  path(['skus'])
);

const cartableSkuSelector = createSelector(
  skusSelector,
  skus => head(skus.filter(Rcompose(not, selectors.isShippingSku)))
);

const shippingSkuSelector = createSelector(
  skusSelector,
  skus => skus.find(selectors.isShippingSku)
);

const propSelectors = createStructuredSelector({
  sku: cartableSkuSelector,
  shippingSku: shippingSkuSelector,
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  withProps(propSelectors),
  withHandlers({
    onTicketClick: ({ addItem, product, sku, shippingSku }) => () =>
      addItem({
        ...sku,
        product,
        quantity: 1,
        shippingSku,
      }),
  })
)(MethodPromo);
