import React from 'react';

import { Row, Col } from '/src/components/atoms/GridRow';

const AlbumDisplay = ({ title, tracks, coverSrc }) => (
  <div className="MethodPromo__album">
    <Row>
      <Col width={6}>
        <div className="MethodPromo__album__art">
          <h3 className="MethodPromo__album__art__title">{title}</h3>
          <div className="MethodPromo__album__art__cover">
            {coverSrc && (
              <img
                src={coverSrc}
                alt={`Album artwork for `}
                className="img-responsive MethodPromo__album__art__image"
              />
            )}
          </div>
        </div>
      </Col>
      <Col width={6}>
        <div className="MethodPromo__album__tracks tl">
          <h3 className="MethodPromo__album__tracks__title">
            {`'${title}' Track Listing`}
          </h3>
          <ol className="MethodPromo__album__tracks__list">
            {tracks && tracks.length
              ? tracks.map(trackName => (
                  <li
                    key={trackName}
                    className="MethodPromo__album__tracks__list__item"
                  >
                    {trackName}
                  </li>
                ))
              : 'To Be Announced...'}
          </ol>
        </div>
      </Col>
    </Row>
  </div>
);

export default AlbumDisplay;
